import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const selectAllCheckbox = this.element.querySelector('.select-all-fields');
    const fieldCheckboxes = this.element.querySelectorAll('.export-field');

    // Handle "Select All" checkbox
    selectAllCheckbox.addEventListener('change', function() {
      fieldCheckboxes.forEach(checkbox => {
        checkbox.checked = this.checked;
      });
    });

    // Update "Select All" when individual checkboxes change
    fieldCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function() {
        const allChecked = Array.from(fieldCheckboxes).every(cb => cb.checked);
        selectAllCheckbox.checked = allChecked;
      });
    });
  }
}
